<script>
import { DOCUMENT_COLOR } from '@/config/agreement-editor.js';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { publishEvent, subscribeTo, unsubscribeFrom } from '@/utils/bus';
import { makeEmailInputDataStore } from '@/utils/helpers/email-input-helpers';
import { getBlobFromUrl, readFileAsDataUrl } from '@/utils/helpers/functions';
import HrbrCkeditorAnnotationEditorModal from '@components/Ckeditor/HrbrCkeditorAnnotationEditorModal.vue';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useAgreementEditorStore } from '../AgreementEditor/stores/agreement-editor-store';
import HrbrAgreementLinkCreatedModal from '../Modals/HrbrAgreementLinkCreated.vue';
import HrbrCkeditorDiffViewer from './HrbrCkeditorDiffViewer.vue';
import HrbrCkeditorInsertAnchorModal from './HrbrCkeditorInsertAnchorModal.vue';
import HrbrCkeditorWrapper from './HrbrCkeditorWrapper.vue';
import { useCkeditorStore } from './stores/ckeditor-store';

export default {
  name: 'HrbrCkeditorModal',

  components: {
    HrbrCkeditorWrapper,
  },

  props: {
    fileVersionDisplayId: {
      type: String,
    },
    htmlData: {
      type: String,
    },
    hasPolling: {
      type: Boolean,
    },
    hideCloseButton: {
      type: Boolean,
    },
    isSharedAgreementPage: {
      type: Boolean,
    },
    preventVersionedHtmlStore: {
      type: Boolean,
    },
    isAgreementEditorWorkflow: {
      type: Boolean,
    },
    isDownloadOnly: {
      type: Boolean,
    },
    uploadedFileName: {
      type: String,
    },
    base64Data: {
      type: String,
    },
    emailInputData: {
      type: Object,
    },
    linkItem: {
      type: Object,
    },
    linkDisplayId: {
      type: String,
    },
    syncComplete: {
      type: Boolean,
    },
    templateBase64: {
      type: String,
    },
    isSuggestingChanges: {
      default: false,
      type: Boolean,
    },
    sourceDraftId: {
      type: String,
      default: null,
    },
    linkSigners: {
      type: Array,
    },
    // edit agreement
    publishLock: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      lastUpdatedTimestamp: null,
      isExporting: false,
      orgId: null,
      activeAgreementId: null,
      isCollaboratorSigner: null,
      $_ckeditorInstance: null,
      hasCkeditorInstance: false,
      documentTitle: null,
      recentlySharedContacts: [],
      readOnlyKey: 'harbour',
      userRole: null,
      editorLoadError: false,
      exportToWordState: 'initial',
      exportToWordBtnIcon: {
        initial: 'fa-file-arrow-down',
        exporting: 'loader',
        completed: 'fa-file-check',
      },
      updateAgreementLinkState: 'initial',
      updateAgreementLinkMessaging: {
        initial: {
          icon: null,
          text: 'Update agreement link',
        },
        working: {
          icon: 'loader',
          text: 'Updating agreement link...',
        },
        stillWorking: {
          icon: 'loader',
          text: 'Almost done...',
        },
        finalizing: {
          icon: 'loader',
          text: 'Finishing up...',
        },
        done: {
          icon: 'fa-check',
          text: 'Done!',
        },
      },
      importNewVersionState: 'initial',
      importNewVersionMessaging: {
        initial: {
          text: 'Upload & compare',
          icon: 'fa-file-arrow-up',
        },
        waiting: {
          text: 'Upload & compare',
          icon: 'fa-file-arrow-up',
        },
        working: {
          text: 'Processing...',
          icon: 'loader',
        },
        comparing: {
          text: 'Comparing...',
          icon: 'loader',
        },
        completed: {
          text: 'Done!',
          icon: 'fa-file-check',
        },
      },
      documentCollaborators: [],
      documentSavingTimeoutId: null,
      documentSavingState: 'initial',
      documentSavingMessaging: {
        initial: {
          icon: 'fa-cloud-check',
          message: null,
        },
        working: {
          icon: 'fa-rotate',
          message: 'Saving...',
        },
        done: {
          icon: 'fa-rotate',
          message: 'Saved',
        },
      },
      documentFirstSave: false,
      documentLockState: 'unlocked',
      userIsDocumentOwner: null,
      initialHtml: null,
      signerRedliningCreatorReview: false,
      syncCompleteInternal: false,
      base64DataInternal: null,
      trackChangesOff: true,
      draftData: null,

      draftAgreementDetails: null
    };
  },

  setup() {
    const harbourStore = useHarbourStore();
    const ckeditorStore = useCkeditorStore();
    const agreementEditorStore = useAgreementEditorStore();
    const draftsStore = useDraftsStore();
    const { draftTemplateChangedData, draftTemplateRemovedInputs } = storeToRefs(draftsStore);

    return {
      harbourStore,
      ckeditorStore,
      agreementEditorStore,
      draftsStore,
      draftTemplateChangedData,
      draftTemplateRemovedInputs
    };
  },

  computed: {
    hasEditorControls() {
      return this.userRole === 'writer';
    },

    showUpdateAgreementFooter() {
      // required agreelink props
      if (!this.linkItem && !this.linkDisplayId) return false;

      // check if shared agreement page
      const isInSharedAgreement = this.signerRedliningCreatorReview || this.isSharedAgreementPage;
      if (isInSharedAgreement) {
        // require specific role
        return this.userIsDocumentOwner || this.userRole === 'writer';
      }

      // check if in dashboard
      const isInDashboard = this.isAgreementEditorWorkflow && this.hasCkeditorInstance;
      return isInDashboard;
    },

    draftAgreementInputs() {
      if (!this.draftAgreementDetails) return [];
      // Template inputs from which draft was created

      const docInputs = this.draftAgreementDetails.agreementcustominputjson.custominputsitems.map(input => ({
        ...input,
        id: input.itemid,
        itemcolor: DOCUMENT_COLOR,
        inputtype: 'DOCUMENT'
      }));

      const signerInputs = this.draftAgreementDetails.agreementcontentjson.sections.map(section => section.sectionitems).map(input => ({
        ...input,
        id: input.itemid,
        inputtype: 'SIGNER'
      }));

      return [
        ...docInputs,
        ...signerInputs,
      ].flat(1)
    },

    continueButton() {
      return {
        text: 'Continue',
        styleType: 'primary',
        style: {
          minWidth: '40px',
        },
        iconLeft: 'fa-light fa-signature',
      }
    },

    shareButton() {
      return {
        text: 'Share',
        styleType: 'secondary',
        style: {
          minWidth: '40px',
        },
        iconLeft: 'fa-light fa-users',
      }
    },

    uploadRevisionButton() {
      return {
        text: this.importNewVersionMessaging[this.importNewVersionState].text,
        styleType: 'secondary',
        iconLeft: 'fa-light ' + this.importNewVersionMessaging[this.importNewVersionState].icon
      }
    },

    exportToWordButton() {
      return {
        text: '',
        styleType: 'secondary',
        iconLeft: 'fa-light ' + this.exportToWordBtnIcon[this.exportToWordState],
        title: 'Download as Word document'
      }
    },

    updateAgreementLinkButton() {
      return {
        text: this.updateAgreementLinkMessaging[this.updateAgreementLinkState].text,
        styleType: 'tertiary',
        iconLeft: 'fa-light ' + this.updateAgreementLinkMessaging[this.updateAgreementLinkState].icon,
        disabled: this.updateAgreementLinkState !== 'initial',
      }
    },
  },

  watch: {
    hasEditorControls(isEditor) {
      if (isEditor && this.documentLockState !== 'locked' && !this.isAgreementEditorWorkflow) {
        this.loadMetadata(this.fileVersionDisplayId);
      }
    },

    documentLockState(lockState) {
      this.$buefy.toast.open({
        message: `Document is ${lockState}`,
        type: 'is-success',
        position: 'is-top',
        duration: 2000,
      });
    },
  },

  methods: {
    onEditorReady({ editor }) {
      // put in window for commentsRepository
      window.fileDisplayId = this.fileVersionDisplayId;
      window.linkDisplayId = this.linkDisplayId;
      this.$_ckeditorInstance = editor;
      this.hasCkeditorInstance = true;

      if (this.isDownloadOnly) {
        this.$buefy.toast.open({
          duration: 9000,
          message: '&#11015;&nbsp;&nbsp;Downloading DOCX document...',
          position: 'is-bottom',
          type: 'is-black',
        });
        this.exportToWordHandler();
        this.closeModal();
      }

      const importWordCommand = editor.commands.get('importWord');
      const onDataInsert = (event, data) => {
        const html = this.ckeditorStore.toUploadHtmlSync(data.html);
        data.html = html;
      };
      if (importWordCommand) importWordCommand.on('dataInsert', onDataInsert);
    },

    onEditorError(error) {
      this.editorLoadError = true;
      if (!error.data) return;

      const { originalError } = error.data;
      const errorObj = originalError.data.errors?.pop();
      if (!errorObj) {
        console.log('CKEditor error event - original error:', originalError);
        return;
      }

      const { message } = errorObj;
      const { explanation } = errorObj;
      console.log('CKEditor error event - message and explanation:', message, explanation);
    },

    onDocumentOwnerUpdate(isOwner) {
      this.userIsDocumentOwner = isOwner;
    },

    onDocumentLockEvent(isLocked) {
      // if user is owner or owner not yet determined, don't show lock message
      if (this.userIsDocumentOwner === null || this.userIsDocumentOwner === true) {
        return;
      }
      this.documentLockState = isLocked ? 'locked' : 'unlocked';
    },

    onUserRoleUpdate(role) {
      this.userRole = role;
      this.$emit('user-role-update', role);
    },

    onShareButtonClick() {
      this.$openCkeditorShareModal({
        props: {
          fileDisplayId: this.fileVersionDisplayId,
          linkDisplayId: this.linkDisplayId,
          emailInputData: this.emailInputData || makeEmailInputDataStore(),
          collaborators: this.documentCollaborators,
          agreementName: this.documentTitle,
        },
        events: {
          'updated-user-access': (updatedUsers) => {
            this.documentCollaborators = updatedUsers;
          },
          'update-recent-contacts': (contacts) => {
            this.recentlySharedContacts = contacts;
          },
          'document-lock-update': (lockState) => {
            this.documentLockState = lockState;
          },
        },
      });
    },

    async loadMetadata(fileDisplayId) {
      const respData = await this.ckeditorStore.loadCkeditorMetadata(fileDisplayId);
      if (!respData) return;
      const { refids } = respData;
      this.activeAgreementId = refids?.activeagreementid;
      this.isCollaboratorSigner = respData.issigner || false;
    },

    async getCurrentCkeditorDocx() {
      const title = this.documentTitle;
      const instance = this.$_ckeditorInstance;

      let html = instance.getData();
      if (html === null || html === undefined) {
        return [null, false];
      }

      // handle blank documents
      if (html.length === 0) html = ' ';

      const comments = await this.ckeditorStore.getCkeditorComments(this.$_ckeditorInstance);
      const suggestions = await this.ckeditorStore.getCkeditorSuggestions(this.$_ckeditorInstance);
      const currentFileBlob = await this.ckeditorStore.exportToDocx(
        title,
        html,
        comments,
        suggestions,
      );

      if (!currentFileBlob) return [null, false];
      const currentFile = new File([currentFileBlob], title);
      return [currentFile, false];
    },

    async downloadCurrentDocx() {
      let [newBlob] = await this.getCurrentCkeditorDocx();
      if (!newBlob) return;

      const download = async (file) => {
        const blobUrl = window.URL.createObjectURL(file);
        let filename = this.documentTitle || 'document.docx';
        if (filename.split('.').pop() !== 'docx') {
          filename += '.docx';
        }
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename);
        document.body.append(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(blobUrl);
      };
      download(newBlob);
    },

    async exportToWordHandler() {
      if (!this.$_ckeditorInstance || this.isExporting) return;

      this.$buefy.toast.open({
        message: 'Downloading...',
        type: 'is-info',
        position: 'is-top',
        duration: 12 * 1000,
      });

      this.isExporting = true;

      const html = this.$_ckeditorInstance.getData();
      const hasHtml = html !== null && html !== undefined;
      if (hasHtml) await this.downloadCurrentDocx();

      this.exportToWordState = 'initial';
      this.isExporting = false;
    },

    onSignClick() {
      const trackChanges = this.$_ckeditorInstance.plugins.get('TrackChanges');
      const commentsRepository = this.$_ckeditorInstance.plugins.get('CommentsRepository');
      const suggestions = trackChanges.getSuggestions({ skipNotAttached: true });
      const comments = commentsRepository.getCommentThreads({ skipNotAttached: true });
      const combined = [...comments, ...suggestions];
      const hasCommentsOrSuggestions = combined.length;

      if (!hasCommentsOrSuggestions) {
        this.prepareForSignature();
        return;
      }
      this.$buefy.dialog.confirm({
        title: 'Unresolved changes or comments',
        message: 'You have unresolved changes or comments.',
        confirmText: 'Continue',
        cancelText: 'Cancel',
        container: '.hrbr-ckeditor-modal-sign-dialog',
        hasIcon: true,
        iconPack: 'fal',
        icon: 'file-pen',
        type: 'is-dark',
        onConfirm: () => {
          this.prepareForSignature();
        },
      });
    },

    prepareForSignature() {
      const eventData = {
        fileVersionDisplayId: this.fileVersionDisplayId,
        activeAgreementId: this.activeAgreementId,
        documentTitle: this.documentTitle,
        creationMode: this.linkItem?.assetsource_contextsourcejson?.role,
        anchors: this.getAnchorsFromHtml(),
      };
      this.$emit('skip-to-ck-complete');
      this.documentLockState = 'locked';

      this.ckeditorStore.lockCkeditorCollaboration({
        fileDisplayId: this.fileVersionDisplayId,
        isCollabEnabled: false,
      });

      this.$emit('prepare-for-signature', eventData);
      this.closeModal({ source: 'signature' });
    },

    openComparisonModal(comparisonId, signature, validUntil, editor, tgtFile) {
      this.$buefy.modal.open({
        fullScreen: true,
        parent: this,
        component: HrbrCkeditorDiffViewer,
        canCancel: false,
        props: {
          comparisonId,
          signature,
          validUntil,
        },
        events: {
          'cancel-import': () => {
            this.importNewVersionState = 'initial';
            this.deleteComparisonUrl(comparisonId);
          },
          'confirm-import': () => {
            this.importNewVersionState = 'working';
            this.processNewDocumentVersion(editor, tgtFile);
          },
        },
      });
    },

    processNewDocumentVersion(editor, tgtFile) {
      this.importNewVersionState = 'working';
      // Lock editor
      editor.enableReadOnlyMode(this.readOnlyKey);
      // Init for new version file UI
      this.isConvertingFile = true;
      this.isConversionTakingTooLong = false;
      this.isConversionFinished = false;
      this.editorDisabled = true;
      this.fileUploadName = tgtFile.name;
      this.comments = [];
      this.suggestions = [];
      // Save new revision
      const revisionTrackerPlugin = editor.plugins.get('RevisionTracker');
      // Updates "current revision", i.e. the revision containing unsaved changes.
      revisionTrackerPlugin.update();
      revisionTrackerPlugin.saveRevision();
      // Process file
      this.callImportFromWordService(tgtFile);
    },

    async callImportFromWordService(tgtFile) {
      const self = this;
      const tgtFileName = tgtFile.name || 'document.docx';

      self.suggestions = [];
      self.comments = [];

      self.conversionTimeout = setTimeout(() => {
        self.isConversionTakingTooLong = true;
      }, 30 * 1000);

      self.importNewVersionState = 'completed';
      setTimeout(() => {
        self.importNewVersionState = 'initial';
      }, 1300);

      try {
        const tgtFileBase64 = await readFileAsDataUrl(tgtFile);
        const respData = await this.ckeditorStore.importFromWord({
          fileName: tgtFileName,
          fileBase64: tgtFileBase64,
          fileVersionDisplayId: self.fileVersionDisplayId,
        });
        if (!respData) throw new Error('Error');

        if (respData.suggestions && respData.suggestions.length > 0) {
          self.suggestions = respData.suggestions;
        }
        if (respData.comment_threads && respData.comment_threads.length > 0) {
          self.comments = respData.comment_threads;
        }

        self.isConversionFinished = true;

        let htmlString = respData.html;
        htmlString = await this.ckeditorStore.toUploadHtml(htmlString);

        let base64Data = btoa(encodeURIComponent(htmlString));
        base64Data = base64Data.split(';base64,').pop();

        // Validate: is original upload?
        let isOriginalFile = false;
        if (self.activeFileVersionDisplayId == null) isOriginalFile = true;

        // Store file version
        self.isStoringFileVersion = true;
        self.storeFileVersion(tgtFile, tgtFileBase64, isOriginalFile, base64Data);

        // If there are no suggestions or comments, there is no need to call CKE CS
        if (self.suggestions.length === 0 && self.comments.length === 0) {
          await self.setInitialData(htmlString);
        } else {
          await self.ckeditorStore.saveCkeditorMetadata({
            fileDisplayId: this.fileVersionDisplayId,
            suggestions: this.suggestions,
            comments: this.comments,
          });
          await self.ckeditorStore.syncCkeditorDocumentMetadata({
            fileDisplayId: this.fileVersionDisplayId,
            suggestions: this.suggestions,
            comments: this.comments,
          });
          await self.setInitialData(htmlString);
        }

        self.$buefy.toast.open({
          message: 'New version added',
          type: 'is-success',
          duration: 3000,
        });
      } catch(err) {
        self.isConvertingFile = false;
        self.isConversionFinished = false;
        self.isConversionTakingTooLong = false;
        console.error(err);
      }
    },

    async convertFileToDocx(fileInfo) {
      if (!fileInfo) return null;

      const fileName = fileInfo.name;
      const fileExt = fileName.split('.').pop().toLowerCase();
      const fileBase64 = await readFileAsDataUrl(fileInfo);

      try {
        const { converted } = await this.convertFile({
          fileBase64,
          fromType: fileExt,
          toType: 'docx',
        });
        const convertedBase64NoMeta = converted.split(';base64,').pop();

        const docxMimeType =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        const base64Meta = `data:${docxMimeType};base64,`;

        const convertedBase64 = `${base64Meta}${convertedBase64NoMeta}`;
        const convertedBlob = await getBlobFromUrl(convertedBase64);
        const convertedFile = new File([convertedBlob], `${fileName.split('.')[0]}.docx`, {
          type: docxMimeType,
        });

        const convertedFileInfo = {
          name: convertedFile.name,
          size: convertedFile.size,
          mimeType: convertedFile.type,
          base64: convertedBase64,
          file: convertedFile,
        };

        return convertedFileInfo;
      } catch (error) {
        this.$buefy.toast.open({
          message:
            'Unable to convert the file at this time. Please convert it to DOCX and try again',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        console.error(error);
      }
    },

    async convertFile({ fileBase64, fromType, toType }) {
      const res = await this.$harbourData.post('agreementeditor-convertdocument', {
        fromtype: fromType,
        totype: toType,
        filebase64: fileBase64,
      });
      return res.data;
    },

    async importRevisionClickHandler() {
      const self = this;
      const editor = self.$_ckeditorInstance;

      // prevent double click
      self.importNewVersionState = 'waiting';
      setTimeout(() => self.importNewVersionState = 'initial', 1000);

      const fileInputElement = document.createElement('input');
      fileInputElement.style.display = 'none';
      fileInputElement.type = 'file';

      async function handleFileInputChange() {
        if (this.files === undefined || this.files === null || this.files.length === 0) {
          self.importNewVersionState = 'initial';
          return;
        }
        let tgtFile = this.files[0];

        if (!(tgtFile.name.endsWith('.docx') || tgtFile.name.endsWith('.doc'))) {
          self.$buefy.toast.open({
            message: 'Error: Please upload a .docx or .doc file',
            type: 'is-danger',
            position: 'is-top',
            duration: 3000,
          });
          self.importNewVersionState = 'initial';
          return;
        }

        self.importNewVersionState = 'working';
        setTimeout(() => self.importNewVersionState = 'comparing', 2300);

        try {
          if (tgtFile.name.endsWith('.doc')) {
            const converted = await self.convertFileToDocx(tgtFile);
            tgtFile = converted.file;
          }

          let tgtFileBase64 = await readFileAsDataUrl(tgtFile);
          const respData = await self.ckeditorStore.importFromWord({
            fileName: tgtFile.name,
            fileBase64: tgtFileBase64,
          });
          if (!respData) throw new Error('Error');

          const currentHtml = editor.getData();
          const tgtHtml = await self.ckeditorStore.toUploadHtml(respData.html);

          const tgtFilePdf = await self.ckeditorStore.exportToPdf(self.documentTitle, tgtHtml);
          const currentFile = await self.ckeditorStore.exportToPdf(self.documentTitle, currentHtml);
          const compareResponse = await self.getFileDiffResponse(currentFile, tgtFilePdf, 'pdf');
          if(compareResponse){
            const {identifier, signature, validUntil} = compareResponse;
            self.openComparisonModal(identifier, signature, validUntil, editor, tgtFile);
          } else {
            throw new Error('Error initializing comparison');
          }

        } catch(err) {
          self.importNewVersionState = 'initial'
          console.error(err);
          Sentry.captureException(err);
        }
      }
      fileInputElement.addEventListener('change', handleFileInputChange, false);

      document.body.append(fileInputElement);
      fileInputElement.click();
    },

    async updateAssociatedLink() {
      const self = this; // parent component scope

      const confirmComponent = {
        methods: {
          async onConfirm() {
            this.$emit('close');

            let linkDisplayId = null;
            self.updateAgreementLinkState = 'working';
            // build payload
            if (self.signerRedliningCreatorReview) {
              // send update email to signer from writer
              // get link display id from url params
              const urlParams = new URLSearchParams(window.location.search);
              const signerEmail = urlParams.get('signer_email');

              linkDisplayId = urlParams.get('link_display_id');
              if (linkDisplayId) {
                await self.$harbourData.post('/signer-redlining-messaging', {
                  signeremail: signerEmail,
                  linkdisplayid: linkDisplayId,
                  ckfileid: self.fileVersionDisplayId,
                  operation: 'update',
                });
              }
            } else {
              const linkItem = self.linkItem;
              linkDisplayId = linkItem?.id || self.linkDisplayId;
            }

            const updatedHtml = self.$_ckeditorInstance.getData();
            const ckeditorAgreementId = self.fileVersionDisplayId;

            // get new AO values from html
            const newCustomInputs = {};
            const parser = new DOMParser().parseFromString(updatedHtml, 'text/html');
            const annotations = parser.querySelectorAll('.annotation');
            annotations.forEach((annotation) => {
              const id = annotation.getAttribute('data-itemid');
              const value = annotation.querySelector('.annotation-text')?.innerHTML;
              if (value === undefined) {
                return;
              }
              newCustomInputs[id] = value;
            });

            const payload = {
              linkdisplayid: linkDisplayId,
              ckeditoragreementid: ckeditorAgreementId,
              updatedhtml: updatedHtml,
              custominputsjson: newCustomInputs,
            };

            const payloadValues = Object.values(payload);
            if (!payloadValues.every((i) => i)) {
              let errorString = 'Could not update agreement!';
              alert(errorString);

              // build string for dev logging
              const errorStringData = Object.entries(payload).map((pair) => {
                let [key, value] = pair;
                const maxLen = 100;
                if (value?.length > maxLen) {
                  value = value.slice(0, maxLen) + '...(truncated)';
                }
                return `${key}: ${value}`;
              });
              errorString += ' - ' + errorStringData.join(', ');
              console.log(errorString);

              self.updateAgreementLinkState = 'initial';

              try {
                Sentry.captureException(new Error(errorString));
              } catch (err) {}
              return;
            }

            const resp = await self.ckeditorStore.storeCkHtmlUpdate({
              linkDisplayId: payload.linkdisplayid,
              ckAgreementId: payload.ckeditoragreementid,
              updatedHtml: payload.updatedhtml,
              customInputs: payload.custominputsjson,
            });

            const {state} = resp;
            if (state === 'FAILURE') {
              self.updateAgreementLinkState = 'initial';
              alert('Unable to update agreement link!');
              return;
            }
            self.setLinkUpdateTimestamp();

            self.updateAgreementLinkState = 'done';
            setTimeout(() => (self.updateAgreementLinkState = 'initial'), 800);

            const linkItem = self.linkItem || {
              id: linkDisplayId,
              url: `${window.location.origin}/agree/${linkDisplayId}`,
              title: self.documentTitle,
            };

            this.$buefy.modal.open({
              parent: self,
              component: HrbrAgreementLinkCreatedModal,
              hasModalCard: true,
              props: {
                shareLink: linkItem.url,
                shareLinkDisplayId: linkItem.id,
                requestLinkTitle: linkItem.title,
                createLinkType: 'UPDATELINK',
                origin: null,
                publishLockEmailRecipients: self.linkSigners,
                publishLock: self.publishLock
              },
            });
          },
        },

        template: `
          <div class="update-agreement-link-confirm modal-card animation-content">
            <header class="modal-card-head">
              <p class="modal-card-title">Confirm updates</p>
            </header>

            <section class="modal-card-body">
              <div class="media">
                <div class="media-content">
                  <p>This agreement link will now be updated to reflect the changes in this document.</p>
                </div>
              </div>
            </section>

            <footer class="modal-card-foot">
              <HrbrButton :button="{ text: 'Cancel', styleType: 'secondary' }" @click="$emit('close')"/>
              <HrbrButton :button="{ text: 'Confirm', styleType: 'primary', style: { marginLeft: '6px' } }" @click="onConfirm"/>
            </footer>
          </div>
        `,
      };

      this.$buefy.modal.open({
        parent: this,
        component: confirmComponent,
        hasModalCard: true,
      });
    },

    async storeFileVersion(
      file,
      fileBase64,
      isOriginalFile = false,
      convertedBase64Data = null,
      convertedTo = null,
    ) {
      // Get file info
      const self = this;
      const fileName = file.name;
      const fileSizeBytes = file.size;

      // Validate: does prior file exists?
      let ancestorDisplayId = null;
      if (isOriginalFile === false) {
        ancestorDisplayId = self.activeFileVersionDisplayId;
      }

      const fileVersionDisplayId = this.agreementEditorStore.generateFileVersionDisplayId();

      try {
        const payload = {
          fileVersionDisplayId,
          fileName,
          fileBase64,
          fileSizeBytes,
          isOriginalFile,
          ancestorDisplayId,
          inputMethod: 'UPLOAD',
        };
        await this.agreementEditorStore.storeUpdateFileVersion(payload);
        self.activeFileVersionDisplayId = fileVersionDisplayId;

        // Validate: stores top parent id for original file only
        if (isOriginalFile === true) {
          self.topParentFileVersionDisplayId = fileVersionDisplayId;
        }

        // If this was converted data, we should finalize the handling of the file
        if (convertedBase64Data != undefined) {
          // Stops conversion loader
          self.isConversionFinished = true;
        }
        // Resets UI
        self.isStoringFileVersion = false;
        self.importNewVersionState = 'completed';
        setTimeout(() => (self.importNewVersionState = 'initial'), 1000);
      } catch (error) {
        self.isStoringFileVersion = false;
        alert(
          'Unable to store file version -- try again in a bit or contract support@harbourshare.com',
        );
        console.log('#storeFileVersion - response error:', error);
      }
    },

    deleteComparisonUrl(comparisonId) {
     try {
         this.$harbourData.post('data?agreementeditor-deletefilediff', {
             requesttype: 'agreementeditor-deletefilediff',
             identifier: comparisonId,
         });
     } catch (error) {
         console.log('Delete comparison error', error);
     }
    },

    async getFileDiffResponse(leftFile, rightFile, extension = 'docx') {
      const formData = new FormData();
      formData.append('left_file', leftFile);
      formData.append('left_file_type', extension);
      formData.append('right_file', rightFile);
      formData.append('right_file_type', extension);
      const { data } = await axios.post('/harbour_draftable', formData);
      if(data && data.state === 'SUCCESS') {
          return data;
      }
      return null;
    },

    async setInitialData(data) {
      const self = this;
      // Loop over comments and find resolved comments.
      // Delete them from the document before we set the data.
      self.comments.forEach((comment) => {
        if (comment.resolved === '1') {
          data = data.replace(`<comment-start name="${comment.threadId}"></comment-start>`, '');
          data = data.replace(`<comment-end name="${comment.threadId}"></comment-end>`, '');
        }
      });

      const outOfDate = await self.ckeditorStore.flushCkeditorIfVersionMismatch(
        self.fileVersionDisplayId,
      );
      if (outOfDate) {
        console.log('CKEditor version was out of date.');
      }

      if (self.initialData === null) {
        console.log('Setting data for the first time for CKE', self.fileVersionDisplayId);
        self.initialData = data;
      } else {
        console.log('Overriding data in CKE', self.fileVersionDisplayId);
        self.$_ckeditorInstance.data.set(data, { suppressErrorInCollaboration: true });

        // Reset file conversion UI
        self.ckeditorInstanceDisabled = false;
        const revisionTrackerPlugin = self.$_ckeditorInstance.plugins.get('RevisionTracker');
        revisionTrackerPlugin.saveRevision({ name: `Upload: ${self.fileUploadName}` });
        self.$_ckeditorInstance.disableReadOnlyMode('harbour');
      }
    },

    onDocumentTitleClick() {
      if (!this.hasEditorControls || !this.documentTitle) return;

      if (this.isSharedAgreementPage) {
        this.promptRenameDraft();
      } else {
        if (!this.draftData) return;
        this.promptRenameDraft();
      }
    },

    promptRenameDraft() {
      this.$buefy.dialog.prompt({
        message: 'Edit document title',
        inputAttrs: {
          value: this.documentTitle,
          maxlength: 300,
          minlength: 1,
        },
        onConfirm: async (newTitle) => {
          const updateName = (name) => {
            this.documentTitle = name;
            this.harbourStore.updateHTMLPageTitle(name);
            this.$emit('update-title', { newTitle: name });
          };

          const draftName = await this.draftsStore.updateDraftNameByFileId(this.fileVersionDisplayId, newTitle);
          updateName(draftName)
        },
      });
    },

    askToCloseModal(e) {
      if (!this.documentFirstSave) {
        this.closeModal(e);
        return;
      }
      this.$buefy.dialog.confirm({
        title: 'Changes saved',
        message: 'All changes were auto-saved.',
        confirmText: 'Close',
        cancelText: 'Return to editor',
        container: '.hrbr-ckeditor-modal-close-dialog',
        hasIcon: true,
        iconPack: 'fas',
        icon: 'circle-check',
        onConfirm: () => {
          this.closeModal(e);
        },
      });
    },

    closeModal(e) {
      const options = {};
      if (e?.source === 'x') {
        options.source = 'x';
        this.harbourStore.resetHTMLPageTitle();
      } else if (e?.source === 'signature') {
        options.source = 'signature';
      }

      let html = '';
      if (this.$_ckeditorInstance) {
        html = this.$_ckeditorInstance.getData();
      } else {
        html = this.htmlData;
      }
      options.html = html;

      this.$emit('ckeditor-modal-close', options);
      try {
        this.$parent?.close();
      } catch {
        console.log('ckeditor modal: No parent modal to close, emitted event');
      }
    },

    updateDocumentSaveMessage(docData) {
      this.documentFirstSave = true;
      this.documentSavingState = 'working';
      clearTimeout(this.documentSavingTimeoutId);
      this.documentSavingTimeoutId = setTimeout(() => {
        this.documentSavingState = 'done';
        setTimeout(() => {
          this.documentSavingState = 'initial';
        }, 1000);
      }, 1000);

      if (!this.isAgreementEditorWorkflow) this.getCkEditorChanges(docData);
    },

    getCkEditorChanges(docData) {
      const ckeditor = this.$_ckeditorInstance;
      const diff = ckeditor.model.document.differ;
      const changes = diff.getChanges();

      const filterChanges = changes.filter(
        (change) => change.type === 'remove' && change.name === 'annotation',
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const change of filterChanges) {
        const attrs = change.attributes;
        const itemId = attrs.get('itemId');
        const contains = docData.includes(itemId);
        if (itemId && !contains) {
          this.removeCkeditorInputMatch(itemId);
        }
      }
    },

    attachEventListeners() {
      this.$parent.$parent.$on('sync-complete', (syncComplete) => {
        this.syncCompleteInternal = syncComplete;
      });
      this.$parent.$parent.$on('base64-data-changed', (base64Data) => {
        this.base64DataInternal = base64Data;
      });
      this.$parent.$parent.$on('html-data-changed', (initialHtml) => {
        this.initialHtml = initialHtml;
      });

      window.addEventListener('global-close-ckeditor-modal', this.closeModal);
      window.addEventListener('ckeditor-insert-anchor-click', this.onCkeditorInsertAnchorClick);

      window.addEventListener('ckeditor-annotation-click', this.onCkeditorAnnotationClick);

      subscribeTo('drafts:ae-draft-created', this.onAgreeEditorDraftCreated);
    },

    removeEventListeners() {
      window.removeEventListener('global-close-ckeditor-modal', this.closeModal);
      window.removeEventListener('ckeditor-insert-anchor-click', this.onCkeditorInsertAnchorClick);

      window.removeEventListener('ckeditor-annotation-click', this.onCkeditorAnnotationClick);

      unsubscribeFrom('drafts:ae-draft-created', this.onAgreeEditorDraftCreated);
    },

    onCkeditorInsertAnchorClick(event) {
      const { editor } = event.detail;
      this.openInsertAnchorModal(editor);
    },

     openInsertAnchorModal(ckeditorInstance) {
      // get selected text
      const editor = ckeditorInstance;
      const selection = editor.model.document.selection;
      const selectedContent = editor.model.getSelectedContent(selection);
      const selectedContentHtml = editor.data.stringify(selectedContent);

      // disable if no text selected
      const isEnabled = selectedContentHtml.length > 0;

      // open confirmation dialog
      this.$buefy.modal.open({
        parent: this,
        component: HrbrCkeditorInsertAnchorModal,
        props: {
          isEnabled,
        },
        hasModalCard: true,
        events: {
          save: (anchorName) => {
            if (!anchorName) return; // no empty anchor names
            const newAnchor = {
              id: 'anchor_' + anchorName + '_' + Date.now() + '_' + Math.trunc(Math.random() * 1e6),
              name: anchorName,
              source: 'ckeditor',
            };
            ckeditorInstance.execute('insertAnchor', newAnchor);
            this.$emit('add-anchor', newAnchor);
          },
        },
      });
    },

    getAnchorsFromHtml() {
      const anchors = [];
      const editor = this.$_ckeditorInstance;
      const htmlString = editor.getData();
      if (!htmlString) anchors;

      const document = new DOMParser().parseFromString(htmlString, 'text/html');
      const anchorElems = [...document.querySelectorAll('a[data-anchorid]')];
      for (const elem of anchorElems) {
        const id = elem.dataset.anchorid;
        const name = elem.getAttribute('name');
        if (!id || !name) continue;
        anchors.push({
          id,
          name,
          source: 'ckeditor',
        });
      }
      return anchors;
    },

    onAgreeEditorDraftCreated(draft) {
      if (this.draftData) return;
      this.draftData = draft;
    },

    async setLinkUpdateTimestamp() {
      const urlParams = new URLSearchParams(window.location.search);
      const linkDisplayId = urlParams.get('link_display_id') || this.linkDisplayId;
      if (!linkDisplayId) {
        this.lastUpdatedTimestamp = '-';
        return;
      }

      const resp = await this.$harbourData.post('/data?agreelinkdata-getupdatetime', {
        requesttype: 'agreelinkdata-getupdatetime',
        linkdisplayid: linkDisplayId,
      });
      const {lastupdate} = resp.data;
      if (!lastupdate) {
        this.lastUpdatedTimestamp = '-';
        return;
      }

      this.lastUpdatedTimestamp = this.displayLocalDateTimeFromEpochMs(lastupdate);
    },

    async getContextData() {
      const contextDict = this.harbourStore.contextDict;
      const organizationId = contextDict?.organizationid;
      if (organizationId) {
        this.orgId = organizationId;
        return;
      }

      try {
        const resp = await this.$harbourData.get('/context');
        const respData = resp.data;
        if (respData) this.orgId = respData.organizationid;
      } catch (err) {
        console.error(err);
      }
    },

    getReviewSuggestionsFromQuery() {
      const urlParams = new URLSearchParams(window.location.search);
      const reviewSuggestions = urlParams.get('review_suggestions');
      if (reviewSuggestions) {
        this.signerRedliningCreatorReview = true;
      }
    },

    async loadDraftData(draftId) {
      if (!draftId) return;
      const draft = await this.draftsStore.loadDraftById(draftId);
      if (draft) {
        this.draftData = draft;

        const { data } = await this.$harbourData.post('data?agreementeditor-getagreementdetails', {
          requesttype: 'agreementeditor-getagreementdetails',
          agreementid: this.draftData.agreementId
        });

        this.draftAgreementDetails = data;
      }
    },

    initDocumentTitle() {
      const options = [
        this.draftData?.name,
        this.linkItem?.linktitle,
        this.uploadedFileName,
      ]
      options.forEach((option) => {
        this.documentTitle = this.documentTitle || option || '';
      });
    },

    async initData() {
      this.trackChangesOff  = !this.isSuggestingChanges;
      this.base64DataInternal = this.base64Data || null;
      this.initialHtml = this.htmlData;
      this.syncCompleteInternal = this.syncComplete;
      this.getReviewSuggestionsFromQuery();
      this.getContextData();

      await this.loadDraftData(this.sourceDraftId);
      this.initDocumentTitle();
      this.harbourStore.updateHTMLPageTitle(this.documentTitle);

      this.setLinkUpdateTimestamp();
    },

    displayLocalDateTimeFromEpochMs(params) {
      const epochMs = params?.value || params;
      let epoch_ms = epochMs;
      if (epochMs == 'NOW') epoch_ms = Date.now();
      try {
        const eventDate = new Date(epoch_ms);
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        };
        return eventDate.toLocaleTimeString('en-US', options);
      } catch (err) {
        return '-';
      }
    },

    onDocumentLockedClick() {
      this.$buefy.toast.open({
        message: 'Document is locked. Please reach out to the document owner to make changes.',
        position: 'is-top',
        duration: 3500,
      });
    },

    onDocumentUpdated() {
      publishEvent('drafts:update-time-updated', this.sourceDraftId);
    },

    removeCkeditorInputMatch(id) {
      return new Promise((resolve) => {

        this.$buefy.dialog.confirm({
          title: 'Remove field',
          message: `Remove connected form field?`,
          confirmText: 'Delete',
          type: 'is-danger',
          onConfirm: () => {
            this.draftTemplateRemovedInputs.push(id);
            resolve();
          },
          onCancel: () => {
            resolve();
          },
        });
      })
    },

    onCkeditorAnnotationClick({ detail }) {
      if (this.isAgreementEditorWorkflow) return;

      const { itemId, content, eventName } = detail;
      const handlers = {
        dblclick: this.handleCkeditorAnnotationDblclick,
      };
      const handler = handlers[eventName];
      if (handler) handler({ itemId, content });
    },

    handleCkeditorAnnotationDblclick({ itemId, content }) {
      const input = this.draftAgreementInputs.find((i) => i.itemid === itemId);
      if (input) {
        input.itemtckvalue = content || input.itemdefaultvalue;
        this.openModalToEditCkAnnotationDefaultValue(input);
      }
    },

    /**
     * @ckeditor method
     * Edit ckeditor annotation text (input itemdefaultvalue) in buefy modal on click.
     * @param {string} input - input item to edit
     */
    openModalToEditCkAnnotationDefaultValue(input) {
      const inputHTMLType =
        input.iteminputtype &&
        input.iteminputtype.replace('hidden', 'text');

      const { itemfieldtype: itemFieldType } = input;
      const { inputtype: itemType } = input;

      const notEditableInputs = [
        'SIGNATUREINPUT',
        'IMAGEINPUT',
        'DROPDOWNINPUT',
        'CHECKBOXINPUT',
        'SSNTEXTINPUT',
        'YESNOINPUT',
        'DISPLAYTEXT',
      ];

      const isNotEditableInput = notEditableInputs.includes(itemFieldType);
      const isDateInput = ['SIGNDATEINPUT', 'DATEINPUT'].includes(itemFieldType);
      const isNotEditableDateInput = isDateInput && ['CREATEDATE', 'SIGNDATE'].includes(input.itemtypeconfig.datetype);

      if (
        isNotEditableInput ||
        isNotEditableDateInput ||
        (itemFieldType === 'HTMLINPUT' && itemType === 'SIGNER')
      ) {
        console.log(
          `openModalToEditCkAnnotationDefaultValue: Input type ${itemType} ${itemFieldType} for ${input.itemid} does not support editing default value, breaking.`,
        );
        return;
      }

      // open confirmation dialog
      this.$buefy.modal.open({
        parent: this,
        component: HrbrCkeditorAnnotationEditorModal,
        props: {
          correspondingInput: input,
          inputType: inputHTMLType,
        },
        hasModalCard: true,
        events: {
          input: (value) => {
            this.draftTemplateChangedData[input.itemid] = value;

            const editedInput = this.draftAgreementInputs.find((i) => i.itemid === input.itemid);
            editedInput.itemtckvalue = value;

            this.ckeditorStore.updateCkeditorAnnotationsText(
              this.$_ckeditorInstance,
              input,
              value,
            );
          },
        },
      });
    },
  },

  async mounted() {
    this.initData();
    this.attachEventListeners();
  },

  beforeDestroy() {
    this.removeEventListeners();
    this.draftsStore.clearCkUrlParams();

    delete window.fileDisplayId;
    delete window.linkDisplayId;
  },

};
</script>

<template>
  <div
    class="hrbr-ckeditor-modal"
    :class="{ 'hrbr-ckeditor-modal--shared-agreement': isSharedAgreementPage }">
    <div class="hrbr-ckeditor-modal-inner">
      <div class="editor-ctn side-panel-open">
        <div class="hrbr-ckeditor-modal_header" v-if="!editorLoadError">
          <div class="header-left">
            <div class="hrbr-agreement-editor-add-fields__header-title">
              <i
                class="hrbr-agreement-editor-add-fields__header-title-icon fa-light fa-file-signature"></i>
              <span class="hrbr-agreement-editor-add-fields__header-title-text"
              :style="{ cursor: !hasEditorControls ? 'not-allowed' : 'pointer' }"
              @click="onDocumentTitleClick">
                {{ documentTitle }}
              </span>

              <!-- Document lock status -->
              <b-tooltip
                v-if="documentLockState === 'locked'"
                label="Document is locked"
                position="is-bottom"
                type="is-light"
                :append-to-body="true">
                <span class="hrbr-agreement-editor-add-fields__header-title-tag">
                  <i class="fa-light fa-lock"></i>
                </span>
              </b-tooltip>

              <!-- document saving icon and message -->
              <b-tooltip
                label="Changes automatically saved"
                position="is-bottom"
                type="is-light"
                :append-to-body="true">
                <span class="hrbr-agreement-editor-add-fields__header-title-tag">
                  <i :class="'fa-light ' + documentSavingMessaging[documentSavingState].icon"></i>
                  {{ documentSavingMessaging[documentSavingState].message }}
                </span>
              </b-tooltip>
            </div>
            <!-- end header title div -->
          </div>
          <!-- end header left -->

          <div class="header-right">
            <!-- upload revision (pending functionality) -->
            <HrbrButton
              v-if="orgId && hasEditorControls"
              :button="uploadRevisionButton"
              @click="
                () => (importNewVersionState !== 'initial' ? null : importRevisionClickHandler())
              "
            />
            <!-- download as word document (icon only) -->
            <HrbrButton
              @click="exportToWordHandler"
              :button="exportToWordButton"
            />

            <!-- prepare for signature (closes modal) -->
            <HrbrButton v-if="userIsDocumentOwner" :button="continueButton" @click="onSignClick"/>

            <!-- open sharing modal -->
            <HrbrButton v-if="orgId && hasEditorControls" :button="shareButton" @click="onShareButtonClick"/>

            <!-- user icons inserted here via ckeditor -->
            <div id="ckeditor-modal-presence"></div>

            <!-- save and close document -->
            <button
              v-if="!hideCloseButton"
              :style="{
                minWidth: '35px',
                width: '35px',
                borderRadius: '60%',
                background: 'none',
                margin: 0,
                color: '#4a4a4a',
              }"
              class="hrbr-ckeditor-modal__header-edit-btn hrbr-ckeditor-modal__header-edit-btn--close"
              @click="askToCloseModal({ source: 'x' })">
              <i class="fa-light fa-x" :style="{ fontSize: '1em', color: '4a4a4a' }"></i>
            </button>
          </div>
          <!-- end header right -->
        </div>
        <!-- end header -->

        <div class="load-error-message" v-if="editorLoadError">
          <div class="message-ctn">
            <div class="icon-ctn">
              <i class="fa-solid fa-file-magnifying-glass"></i>
              <h1>Couldn't load that document.</h1>
            </div>
            <div class="copy-ctn">
              <p>Please make sure you have the correct link.</p>
            </div>
          </div>
        </div>

        <HrbrCkeditorWrapper
          v-if="isSharedAgreementPage ? initialHtml : true"
          :base64-data="base64DataInternal"
          :html-data="initialHtml"
          :toolbar-hidden="false"
          :editing-enabled="true"
          :track-changes-off="trackChangesOff"
          :sync-complete="syncCompleteInternal"
          :is-collaborator-signer="isCollaboratorSigner"
          :file-version-display-id="fileVersionDisplayId"
          :link-display-id="linkItem?.id || linkDisplayId"
          :presence-list="'ckeditor-modal-presence'"
          :toolbar="'ckeditor-modal-toolbar'"
          :revision-viewer="'ckeditor-modal-revision-viewer'"
          :revision-viewer-editor="'ckeditor-modal-revision-editor'"
          :revision-viewer-sidebar="'ckeditor-modal-revision-sidebar'"
          :sidebar-postfix="'_modal'"
          :has-polling="hasPolling"
          :is-download-only="isDownloadOnly"
          :prevent-versioned-html-store="preventVersionedHtmlStore"
          :is-shared-agreement-page="isSharedAgreementPage"
          @document-changed="updateDocumentSaveMessage"
          @editor-error="onEditorError"
          @user-role-update="onUserRoleUpdate"
          @document-locked-update="onDocumentLockEvent"
          @document-owner-update="onDocumentOwnerUpdate"
          @editor-ready="onEditorReady"
          @document-updated="onDocumentUpdated"
          @document-locked-click="onDocumentLockedClick">
        </HrbrCkeditorWrapper>

        <!-- button to update existing agreement link -->
        <div class="update-link-ctn side-panel-open" v-if="showUpdateAgreementFooter">
          <HrbrButton
            :button="updateAgreementLinkButton"
            @click="updateAssociatedLink"
          />
          <p class="last-updated-date" v-if="lastUpdatedTimestamp">
            Last updated: {{ lastUpdatedTimestamp }}
          </p>
        </div>
      </div>
      <!-- end editor ctn -->

      <div class="hrbr-ckeditor-modal-close-dialog"></div>
      <div class="hrbr-ckeditor-modal-sign-dialog"></div>
    </div>
    <!-- end modal inner -->
  </div>
</template>

<style lang="postcss" scoped></style>

<style>
.hrbr-ckeditor-modal {
  /* padding: 4em; */
  --modal-header-height: 62px;
  --modal-toolbar-height: 40px;
  --modal-header-toolbar-height: calc(var(--modal-header-height) + var(--modal-toolbar-height));
}

.hrbr-ckeditor-modal .hrbr-ckeditor-modal-inner {
  display: flex;
  background: white;
}

.hrbr-ckeditor-modal .editor-ctn {
  background: white;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.hrbr-ckeditor-modal .ck-presence-list__counter {
  display: none;
}

.hrbr-ckeditor-modal .editor-ctn.side-panel-open {
  width: 100%;
}

.hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-btn {
  font-family: inherit;
  font-size: 16px;
  color: #fff;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  height: 36px;
  min-width: 110px;
  padding: 8px 15px;
  cursor: pointer;
  color: black;
  background: #ebf3f8;
  border-radius: 4px;
  border: none;
  outline: none;
  appearance: none;
  transition: all 0.2s ease;
  margin-right: 1em;
}

.hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-btn:hover {
  background: #d1d9de;
}

.hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-edit-btn {
  font-family: inherit;
  font-size: 16px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  height: 36px;
  min-width: 110px;
  padding: 8px 15px;
  cursor: pointer;
  background: var(--main-primary-color);
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  appearance: none;
  transition: all 0.2s ease;
  margin-right: 1em;
}

.hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-edit-btn:hover {
  background: var(--main-primary-color-activefocus);
}

/* document title  */

.hrbr-ckeditor-modal .hrbr-agreement-editor-add-fields__header-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 6px;
}

.hrbr-ckeditor-modal .hrbr-agreement-editor-add-fields__header-title-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
  cursor: pointer;
}

.hrbr-ckeditor-modal .hrbr-agreement-editor-add-fields__header-title-icon {
  color: var(--main-primary-color);
}

.hrbr-ckeditor-modal .hrbr-agreement-editor-add-fields__header-title-tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;
  height: 24px;
  padding: 0 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.hrbr-ckeditor-modal .hrbr-agreement-editor-add-fields__header-title-tag i {
  font-size: 14px;
}

.hrbr-ckeditor-modal .hrbr-ckeditor-modal_header {
  position: fixed;
  z-index: 2;
  width: 100%;
  background: white;
  display: flex;
  padding: 0.5em 1em;
  justify-content: space-between;
  align-items: center;
  height: var(--modal-header-height);
  border-right: solid 1px #ccced1;
}

.hrbr-ckeditor-modal .hrbr-ckeditor-modal_header .header-left,
.hrbr-ckeditor-modal .hrbr-ckeditor-modal_header .header-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hrbr-ckeditor-modal .hrbr-ckeditor-modal_header .header-left .inputs-panel-arrows,
.hrbr-ckeditor-modal .hrbr-ckeditor-modal_header .header-right .inputs-panel-arrows {
  cursor: pointer;
}

/* share with group */
.hrbr-ckeditor-modal .fa-plus::before {
  position: relative;
  content: '\2b';
  /* width: 11px; */
  font-size: 11px;
  left: -5px;
}

/* centered error message */
.hrbr-ckeditor-modal .load-error-message {
  background-color: #f4f6f7;
}

.hrbr-ckeditor-modal .load-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hrbr-ckeditor-modal .load-error-message .message-ctn {
  position: relative;
  top: -11em;
  background-color: white;
  padding: 2em;
}

.hrbr-ckeditor-modal .load-error-message .message-ctn i {
  margin-right: 5px;
}

.hrbr-ckeditor-modal .load-error-message .message-ctn h1,
.hrbr-ckeditor-modal .load-error-message .message-ctn i {
  color: #2d71ad;
  font-size: 2em;
}

.hrbr-ckeditor-modal .load-error-message .message-ctn .icon-ctn {
  display: flex;
  align-items: center;
}

.hrbr-ckeditor-modal .ck-comment-marker {
  background: #fef7a9a8;
}

div[data-testid='workspace-toolbar'] {
  display: none;
}

.hrbr-ckeditor-modal .hrbr-ckeditor-wrapper {
  height: calc(100vh - var(--modal-header-height));
}

.hrbr-ckeditor-modal .hrbr-ckeditor-wrapper__editable-container {
  max-height: 100%;
}

/* update corresponding agreement link styles */
.hrbr-ckeditor-modal .update-link-ctn {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color: white;
  padding: 1em;
  z-index: 1;
}

.hrbr-ckeditor-modal button {
  min-width: 40px;
}

/* .hrbr-ckeditor-modal .update-link-ctn.side-panel-open {
  width: 70%;
} */

.hrbr-ckeditor-modal .update-link-ctn .last-updated-date {
  color: #acacac;
  font-size: 0.75em;
}
.last-updated-date {
  margin-top: 0.5em;
}
/* confirm modal */
.update-agreement-link-confirm {
  max-width: 400px;
}

.update-agreement-link-confirm .button-confirm {
  background-color: #2d71ad;
  color: white;
}

.hrbr-ckeditor-modal-sign-dialog .dialog .modal-card {
  max-width: 540px;
}

.hrbr-ckeditor-modal-sign-dialog .modal-card-foot .button:first-child {
  background-color: #d9d9d9;
  border-color: transparent;
  color: #363636;
}

.hrbr-ckeditor-modal-sign-dialog .modal-card-foot .button:last-child {
  background-color: #2d71ad;
  color: #fff;
}

.hrbr-ckeditor-modal-close-dialog .dialog .modal-card .modal-card-foot,
.hrbr-ckeditor-modal-sign-dialog .dialog .modal-card .modal-card-foot {
  justify-content: space-between;
}

.hrbr-ckeditor-modal-close-dialog .modal-card-foot .button {
  margin: 0;
}

.hrbr-ckeditor-modal-close-dialog .modal-card-foot .button:first-child {
  border: none;
  background: none;
  padding: 0;
  height: auto;
  border-bottom: 1px solid #363636;
  border-radius: 0;
}

.hrbr-ckeditor-modal-close-dialog .modal-card-body .media {
  align-items: center;
}

.hrbr-ckeditor-modal-close-dialog .modal-card-body .icon {
  color: #48c78e !important;
}

.hrbr-ckeditor-modal-close-dialog .modal-card-body .media-left {
  margin-left: 5px;
}

.hrbr-ckeditor-modal .update-agreement-link-btn span {
  display: flex;
  align-items: center;
}

.hrbr-ckeditor-modal .update-agreement-link-btn span i {
  margin-right: 0.5em;
}

.hrbr-ckeditor-modal .ckeditor-revision-sidebar {
  height: 100vh;
  overflow: scroll;
}

/* revision history - revision toolbar (back to editing) */
.hrbr-ckeditor-modal .ckeditor-revision-viewer-inner .ck.ck-editor {
  margin-top: 6em;
}

/* revision history - document */
.hrbr-ckeditor-modal .ckeditor-revision-viewer-inner .ck.ck-content.ck-editor__editable {
  display: block;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 10em;
}

@media (max-width: 768px) {
  .hrbr-ckeditor-modal .hrbr-ckeditor-modal_header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-btn,
  .hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-edit-btn {
    min-width: 40px;
    padding: 8px 12px;
    margin-right: 8px;
  }

  .hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-edit-btn--close {
    min-width: 30px !important;
    width: 30px !important;
    padding: 8px 5px !important;
    margin: 0 0 0.5em 0!important;
  }

  .hrbr-ckeditor-modal #ckeditor-modal-presence,
  .hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-btn--upload-compare,
  .hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-btn-text,
  .hrbr-ckeditor-modal .hrbr-ckeditor-modal__header-btn-angle-icon,
  .hrbr-ckeditor-modal .hrbr-agreement-editor-add-fields__header-title-icon {
    display: none;
  }

  .hrbr-ckeditor-modal .hrbr-agreement-editor-add-fields__header-title {
    font-size: 16px;
  }

  .hrbr-ckeditor-modal .hrbr-agreement-editor-add-fields__header-title-text {
    max-width: 70px;
  }
}
</style>
